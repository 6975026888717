import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import { Icon, Table, Header, Popup, Dropdown, Button, Tab, Loader, Segment, Image } from 'semantic-ui-react';

import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import moment from 'moment';
import _ from 'lodash';

import { client_id } from '../../api';
import Calendar from '../../components/Calendar';
import Modal from '../../components/Modal';
import Pagination from '../../components/Pagination';
import Page from '../../components/Page';
import Avatar from '../../components/Avatar';

import { __ } from '../../i18n';
import * as utils from '../../utils';

const PAGE_SIZE = 30;

const limitOptions = [
  { text: __('Last %s days', 7), value: '7' },
  { text: __('Last %s days', 15), value: '15' },
  { text: __('Last %s days', 30), value: '30' },
  { text: __('Last week'), value: 'week' },
  { text: __('Last month'), value: 'month' }
];
const months = [__('jan'), __('feb'), __('mar'), __('apr'), __('may'), __('jun'), __('jul'), __('aug'), __('sep'), __('oct'), __('nov'), __('dec')];
const styles = {
  dropdownItem: {
    height: 65,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 16px',
    cursor: 'pointer',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
};

const getPaymentMethod = (type, method) => {
  if (type === 'WITHDRAWAL' || type === 'WITHDRAWAL_RETURN') return '-';

  if (method === 'BOLETO') return __('Boleto');
  if (method === 'PIX') return __('Pix');
  if (method === 'CARD') return __('Card');
  return method;
};

@inject('store')
@graphql(gql`query OrganizationAccountTransactions(
  $id:ID!
  $dateInterval: OrganizationAccountTransactionsDateInterval!,
  $limit:Int!
  $offset:Int!){
  node(id:$id) {
    ...on OrganizationAccount{
      id: dbId
      transactions(
        dateInterval: $dateInterval,
        limit:$limit,
        offset:$offset
      ){
        id: key
        externalId
        created
        paymentDate
        amount
        installments
        currentInstallment
        paymentMethod
        type
        entity {
          id: dbId
          fullname
          picture {
            uri
            id: dbId
            key
          }
        }
        user {
          id: dbId
          fullname
        }
        charge {
          id: dbId
          name
          dateLimit
          payments {
            nodes {
              status
            }
          }
        }
      }
    }
  }
}
`, {
  options: ownProps => ({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: ownProps.params.account_id,
      dateInterval: {
        limit: ownProps.location.query.startDate ? moment().startOf('day').diff(moment(ownProps.location.query.startDate).startOf('day'), 'days') : (moment().date() - 1),
        offset: ownProps.location.query.endDate ? moment().startOf('day').diff(moment(ownProps.location.query.endDate).startOf('day'), 'days') : 0,
      },
      limit: PAGE_SIZE,
      offset: 0
    }
  })
})
@observer
export default class Statement extends Page {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      page: 1,
      tab: parseInt(props.location.query.tab || 0, 10),
      loadingTab: false
    };
  }

  loadMore = (edgeName, nodeName = 'node') => {
    const { data } = this.props;
    this.setState({ page: this.state.page + 1, loadingTab: false });
    data.fetchMore({
      variables: {
        offset: data[nodeName][edgeName].length
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        // Don't do anything if there weren't any new items
        if (!fetchMoreResult || fetchMoreResult[nodeName][edgeName].length === 0) {
          return previousResult;
        }
        fetchMoreResult[nodeName][edgeName] = [...previousResult[nodeName][edgeName], ...fetchMoreResult[nodeName][edgeName]];

        return fetchMoreResult;
      },
    });
  }
  handleDropdownClick = ({ value }) => {
    let startDate;
    let endDate;
    let filter = value;

    if (value === 'week') {
      filter = 'week';
      endDate = moment().startOf('week').subtract(1, 'days').format();
      startDate = moment().subtract(1, 'weeks').startOf('week').format();
    } else if (value === 'month') {
      filter = 'month';
      endDate = moment().startOf('month').subtract(1, 'days').format();
      startDate = moment().subtract(1, 'month').startOf('month').format();
    } else {
      startDate = moment().subtract(value, 'days').format();
      endDate = moment().format();
    }

    this.setState({ filter, page: 1 });
    this.onParameterChange({ startDate, endDate, custom: false, tab: `${this.state.tab}` });
  }
  onTabChange = (e, value) => {
    this.setState({ tab: value.activeIndex, page: 1, loadingTab: true });
    // activeIndex = 1 means future transactions
    const startDate = value.activeIndex ? moment().add(1, 'day').format('YYYY-MM-DDT00:00:00.000Z') : moment().startOf('month').format('YYYY-MM-DDT00:00:00.000Z');
    const endDate = value.activeIndex ? moment().add(1, 'month').format('YYYY-MM-DDT23:59:59.000Z') : moment().format();

    this.onParameterChange({ startDate, endDate, custom: !!value.activeIndex, filter: '', tab: `${value.activeIndex}` });
  }
  onParameterChange = (data) => {
    const parameters = Object.keys(data).map(name => ({ name, value: data[name] }));

    this.onMultipleParametersChange(parameters);
  }
  handleCalendarClick = (start, end) => {
    let startDate = start;
    let endDate = end;

    if (endDate && startDate) {
      if (startDate > endDate) {
        startDate = [endDate, endDate = startDate][0];
      }
      if (this.dropdown) this.dropdown.close();

      this.setState({ page: 1, loadingTab: false });

      this.onParameterChange({ startDate: moment(startDate).format('YYYY-MM-DDT00:00:00.000Z'), endDate: moment(endDate).format(), custom: true, filter: '', tab: `${this.state.tab}` });
    }
  }

  renderFilter = () => {
    const { data, location } = this.props;
    const { custom, startDate: selectedStartDate, endDate: selectedEndDate } = location.query;
    const { filter } = this.state;

    let startDate = new Date(moment().subtract(30, 'days'));
    let endDate = new Date(moment());
    let selectedLimit = { text: __('Last %s days', 30) };

    if (!custom && filter === 'week') {
      const endOfLastWeek = moment().startOf('week').subtract(1, 'days');
      const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week');

      endDate = new Date(endOfLastWeek);
      startDate = new Date(startOfLastWeek);
      selectedLimit = limitOptions.find(option => option.value === 'week');
    } else if (!custom && filter === 'month') {
      const endOfLastMonth = moment().startOf('month').subtract(1, 'days');
      const startOfLastMonth = moment().subtract(1, 'month').startOf('month');

      endDate = new Date(endOfLastMonth);
      startDate = new Date(startOfLastMonth);
      selectedLimit = limitOptions.find(option => option.value === 'month');
    } else {
      endDate = selectedEndDate ? new Date(selectedEndDate) : new Date();
      startDate = selectedStartDate ? new Date(selectedStartDate) : new Date(moment().startOf('month'));

      if (custom) {
        selectedLimit = {
          text: __('%s to %s', `${startDate.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 })}/${months[startDate.getMonth()]}`, `${endDate.getDate().toLocaleString(undefined, { minimumIntegerDigits: 2 })}/${months[endDate.getMonth()]}`),
        };
      } else {
        selectedLimit = limitOptions[limitOptions.findIndex(option => option.value === filter)] || { text: __('This month') };
      }
    }

    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);

    return (
      <div key={0} style={{ margin: '10px 0px 10px 5px' }}>
        <div>
          <Icon name="filter" />
          <strong>{`${__('Available on')}:`}</strong>
          <span style={{ color: '#4a90e2', marginLeft: '10px' }}>
            <Dropdown ref={(c) => { this.dropdown = c; }} inline data-action={'open-more-settings'} basic text={!data.loading && selectedLimit.text} loading={data.loading}>
              <Dropdown.Menu onClick={e => e.stopPropagation()}>
                <div style={{ display: 'flex', color: 'black', padding: '4px 0px', borderRadius: '4px', boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)', border: 'solid 1px #e9ecef', backgroundColor: 'white' }}>
                  {
                    !this.state.tab ?
                      (<div style={{ marginBottom: 'auto', marginTop: 'auto' }}>
                        {
                          limitOptions.map((option, i) =>
                            <Dropdown.Item
                              key={i}
                              {...option}
                              style={{ ...styles.dropdownItem, backgroundColor: option === selectedLimit ? 'rgba(0, 118, 255, 0.07)' : 'white' }}
                              active={option === selectedLimit}
                              onClick={() => this.handleDropdownClick(option)}
                            />
                          )
                        }
                      </div>) : ''
                  }
                  <div style={{ padding: '5px 16px', borderLeft: 'solid 1px #e9ecef' }}>
                    <p style={{ marginBottom: '8px', marginTop: '4px', fontSize: '1.1em' }}>{__('Custom')}</p>
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: '10px' }}>
                        <p style={{ marginBottom: '-0.4em' }}>{__('Begin')}</p>
                        <Calendar
                          withBorder
                          withPadding
                          calendarStyles
                          headerAlwaysOn
                          selectedDt={startDate}
                          maxDate={new Date(endDate.getTime() - (24 * 60 * 60 * 1000))}
                          minDate={this.state.tab ? new Date() : new Date(2020, 0, 1)}
                          onSelect={date => this.handleCalendarClick(date.selectedDt, endDate)}
                        />
                      </div>
                      <div>
                        <p style={{ marginBottom: '-0.4em' }}>{__('End')}</p>
                        <Calendar
                          withBorder
                          withPadding
                          calendarStyles
                          headerAlwaysOn
                          selectedDt={endDate}
                          minDate={startDate}
                          maxDate={this.state.tab ? new Date(new Date().getTime() + (24 * 60 * 60 * 1000 * 365)) : new Date()}
                          onSelect={date => this.handleCalendarClick(startDate, date.selectedDt)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </span>
        </div>
      </div>
    );
  }

  onClose = () => {
    const { params, router } = this.props;
    router.push(`/organizations/${params.organization_id}/accounts`);
  }

  render() {
    const { data, store } = this.props;
    if ((data.loading && !data.node) || !data.node) return <Modal loading />;

    const { transactions } = data.node;
    const hasNextPage = (transactions.length === PAGE_SIZE * this.state.page) || data.loading;
    const tabs = [{ menuItem: __('Available'), value: 'past' }, { menuItem: __('Receivable'), value: 'future' }];
    const query = { ...location.query };
    const lang = store.app.locale ? (store.app.locale === 'pt-BR' ? 'pt' : store.app.locale) : 'en';
    const exportLink = `${store.app.url}/csv/organization/account/statement?client_id=${client_id}&` +
      `limit=${query.limit || 1000}&offset=${query.p || 0}&id=${data.variables.id}` +
      `&access_token=${encodeURIComponent(store.access_token)}&tz_offset=${-(new Date().getTimezoneOffset())}` +
      `&locale=${lang}&date_limit=${data.variables.dateInterval.limit}&date_offset=${data.variables.dateInterval.offset}`;

    return (
      <Modal id="AccountStatement" open size="fullscreen" closeIcon="times close inside" onClose={this.onClose} style={(!hasNextPage || transactions.length < 10) && { paddingBottom: 40 }}>
        <Modal.Header>{__('Statement')}</Modal.Header>
        <Modal.Content>
          <div>
            <Tab
              menu={{ secondary: true, pointing: true, color: 'blue' }}
              panes={tabs}
              onTabChange={this.onTabChange}
              activeIndex={this.state.tab}
              style={{ margin: '0px 0px 15px 0px' }}
            />
          </div>
          <div style={{ margin: 0, display: 'flex', justifyContent: 'space-between' }}>
            {this.renderFilter()}
            <div style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Button style={{ height: 40, border: '1px solid #e9ecef', borderRadius: 4, display: 'flex' }} as="a" href={exportLink}><Icon name="download" />{__('Export')}</Button>
            </div>
          </div>
          <Table>
            <Table.Header style={{ minHeight: '65px' }}>
              <Table.Row>
                <Table.HeaderCell collapsing>{__('Due date in')}</Table.HeaderCell>
                <Table.HeaderCell collapsing>{__('Approved on')}</Table.HeaderCell>
                <Table.HeaderCell collapsing>{__('Available on')}</Table.HeaderCell>
                <Table.HeaderCell collapsing>{__('Method')}</Table.HeaderCell>
                <Table.HeaderCell collapsing>{__('Installments')}</Table.HeaderCell>
                <Table.HeaderCell collapsing>{__('Charge name')}</Table.HeaderCell>
                <Table.HeaderCell collapsing>{__('Net inflow')}</Table.HeaderCell>
                <Table.HeaderCell collapsing>{__('Net outflow')}</Table.HeaderCell>
                <Table.HeaderCell collapsing>{__('Type')}</Table.HeaderCell>
                <Table.HeaderCell collapsing style={{ width: 160, maxWidth: 160 }}>{__('Entity')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body >
              {
                data.loading && this.state.loadingTab ?
                  <Table.Row key={0} textAlign="center"><Table.Cell colSpan={10}><Loader active className="workaround" inline="centered" /></Table.Cell></Table.Row>
                  :
                  (transactions.length === 0 ?
                    <Table.Row key={0} textAlign="center">
                      <Table.Cell colSpan={10}>
                        <Segment basic textAlign="center">
                          <Image style={{ maxHeight: 400 }} src={utils.asset('/images/ilustracoes-30.png')} centered />
                          <div style={{ color: '#9696a0' }}>{__('No data found!')}</div>
                        </Segment>
                      </Table.Cell>
                    </Table.Row> :
                    transactions.map(transaction =>
                      <Table.Row key={transaction.key}>
                        <Table.Cell key={0}>
                          {transaction.type !== 'WITHDRAWAL' && transaction.type !== 'WITHDRAWAL_RETURN' ? transaction.charge && transaction.charge.dateLimit && moment(transaction.charge.dateLimit).format('DD/MM/YYYY') : '-'}
                        </Table.Cell>
                        <Table.Cell key={1}>
                          {transaction.type !== 'WITHDRAWAL' && transaction.type !== 'WITHDRAWAL_RETURN' ? (transaction && transaction.created && moment(transaction.created).format('DD/MM/YYYY')) : '-'}
                        </Table.Cell>
                        <Table.Cell key={2} style={{ textDecoration: transaction.type === 'TRANSACTION' && transaction.charge && transaction.charge.payments && transaction.charge.payments.nodes.every(p => p.status !== 'PAID') ? 'line-through' : 'none' }}>
                          {transaction.type === 'TRANSACTION' ? (transaction && transaction.paymentDate && moment(transaction.paymentDate).format('DD/MM/YYYY')) : '-'}
                        </Table.Cell>
                        <Table.Cell key={3}>
                          {getPaymentMethod(transaction.type, transaction.paymentMethod)}
                        </Table.Cell>
                        <Table.Cell key={4}>
                          {
                            transaction.type !== 'WITHDRAWAL' && transaction.type !== 'WITHDRAWAL_RETURN' && transaction.paymentMethod === 'CARD' && transaction.currentInstallment && transaction.installments ? `${transaction.currentInstallment}/${transaction.installments}` : '-'
                          }
                        </Table.Cell>
                        <Table.Cell key={5}>
                          {transaction.type !== 'WITHDRAWAL' && transaction.type !== 'WITHDRAWAL_RETURN' ? transaction.charge && transaction.charge.name : '-'}
                        </Table.Cell>
                        <Table.Cell key={6} style={{ color: transaction.type === 'TRANSACTION' && 'green' }}>
                          {transaction.type === 'TRANSACTION' || transaction.type === 'WITHDRAWAL_RETURN' ? `+ ${transaction.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` : '-'}
                        </Table.Cell>
                        <Table.Cell key={7} style={{ color: (transaction.type === 'WITHDRAWAL' && 'red') || (transaction.type === 'REFUND' && 'purple') }}>
                          {transaction.type !== 'TRANSACTION' && transaction.type !== 'WITHDRAWAL_RETURN' ? `- ${transaction.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` : '-'}
                        </Table.Cell>
                        <Table.Cell key={8}>
                          {transaction.type === 'WITHDRAWAL' ? __('Transfer') : ((transaction.type === 'WITHDRAWAL_RETURN' && __('Withdrawal Error')) || (transaction.type === 'REFUND' && __('Refund')) || __('Transaction'))}
                          {transaction.type === 'WITHDRAWAL_RETURN' && <Popup
                            hoverable
                            style={{ marginLeft: '-14px' }}
                            trigger={<Icon name="red exclamation circle outline" size="large" style={{ position: 'absolute' }} />}
                            content={(
                              <div>
                                <p>{__('There was an error with your withdraw please get in touch with the support team before attempting to withdraw again')}</p>
                              </div>
                            )}
                          />}
                        </Table.Cell>
                        <Table.Cell key={9}>
                          {transaction.entity && transaction.user ?
                            (
                              <Header as="h5" className="entityItem" title={transaction.entity.fullname} data-id={transaction.entity.id}>
                                <Avatar avatar spaced="right" src={transaction.entity.picture && transaction.entity.picture.uri} alt={transaction.entity.fullname || ''} style={{ width: 40, height: 40 }} />
                                <Header.Content style={{ paddingLeft: 8 }}>
                                  {
                                    transaction.entity.fullname ?
                                      (
                                        transaction.entity.fullname.length > 10 ?
                                          <Popup
                                            trigger={<Link className="bold defaultClspColor" to={`/entities/${transaction.entity.id}`} >{transaction.entity.fullname.substring(0, 10).trim()}...</Link>}
                                            content={transaction.entity.fullname}
                                          />
                                          :
                                          <Link className="bold defaultClspColor" to={`/entities/${transaction.entity.id}`}>{transaction.entity.fullname}</Link>
                                      )
                                      :
                                      null
                                  }
                                  <Header.Subheader>
                                    {
                                      transaction.user.fullname && transaction.user.fullname.length > 10 ?
                                        <Popup
                                          trigger={<div style={styles.ellipsis}>{transaction.user.fullname.substring(0, 10).trim()}...</div>}
                                          content={transaction.user.fullname}
                                        />
                                        : null}
                                  </Header.Subheader>
                                </Header.Content>
                              </Header>
                            ) : '-'}
                        </Table.Cell>
                      </Table.Row>
                    )
                  )
              }
            </Table.Body>
          </Table>
          {!(data.loading && this.state.loadingTab) ? <Pagination
            loaderInModal
            pagination={{
              graphql: true,
              hasNextPage,
              loading: data.loading
            }}
            onLoadMore={() => this.loadMore('transactions')}
          /> : ''}
        </Modal.Content>
      </Modal>
    );
  }
}
